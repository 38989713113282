import React from 'react';

function Privacy() {

    return (
        <div>
            <div className="header-container">
                <div className="wrapper">
                    <header>
                        <div className="hero-content">
                            <span>
                                Vartalap (“Vartalap,” “us” “our” or “we”) respects your privacy and is committed to protecting it through this privacy policy (the “Privacy Policy”). This Privacy Policy describes how we collect, use, store, share, disclose and protect your information in connection with our website, www.vartalapai.com (the “Site”), and the VBOT software, which includes chat, visitor tracking and any other additional services that Vartalap may add in the future. This policy applies only to information we collect through the Site or Vartalap software and any emails, texts or other electronic communications sent through the Site or the software. This Privacy Policy is part of and governed by our Terms of Service.
                            </span>
                            <span>
                                This policy DOES NOT apply to information collected offline, through any other apps or websites (including websites you may access through the Sites or VBOT software) or by any third party, including through any application or content (including advertising) that may link to or be accessible from or on the Site. These other websites, apps or third parties may have their own privacy policies, which we encourage you to read before providing information through them.
                            </span>
                            <span>
                                NOTICE REGARDING DISPUTE RESOLUTION: Any disputes or claims arising under this Privacy Policy, or relating in any way to your use of the Site or Vartalap software, will be resolved by final and binding arbitration as provided for in our Terms of Service. Unless you opt out, you will only be able to pursue claims against Vartalap on an individual basis, not as part of any class or representative action or proceeding, and you will only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on an individual basis.
                            </span>
                            <h1>
                                Vartalap Customers and Vartalap Customer Visitors.
                            </h1>
                            <span>
                                You may be interacting with Vartalap as a customer (or potential customer) of Vartalap, who purchases from Vartalap's rights to use the Vartalap Software our other Vartalap Software on your own websites (an “Vartalap Customer”). Alternatively, you may be a visitor to one of Vartalap ’s Customer’s websites, which uses the Vartalap Software on such Customer’s website (an “Vartalap Customer Visitor”). If you are an Vartalap Customer Visitor you should review the privacy policy of the Vartalap Customer’s website, which will govern your use of the Vartalap Customer’s website and explain how the Vartalap Customer treats information collected through such Vartalap Customer’s use of the Vartalap Software implemented on such website. This Privacy Policy merely describes how we may collect, use, store, share, disclose and protect Vartalap Customer Visitor information we obtain through our Vartalap Software (as implemented on Vartalap Customers’ websites). We have no control over the privacy practices of Vartalap Customers. Whether you are an Vartalap Customer or Vartalap Customer Visitor, please read this policy carefully to understand our policies and practices regarding the collection and use of your information. If you do not agree with this Privacy Policy, do not access or use the Site, Vartalap Software or, if you are an Vartalap Customer Visitor, the Vartalap Customer’s website. By accessing or using the Site, Vartalap Software or (if you are an Vartalap Customer Visitor) the Vartalap Customer’s website, you agree to this Privacy Policy.
                            </span>
                            <h1>
                                Changes to Our Privacy Policy
                            </h1>
                            <span>
                                We may update our Privacy Policy from time to time. If we make material changes to how we treat your personal information, we will post the new privacy policy on this page. Your continued use of the Site or Vartalap Software after we make changes are deemed to be your acceptance of those changes, so please check this policy periodically for updates. The date that this Privacy Policy was last revised is identified at the top of the page. You are responsible for periodically visiting this Privacy Policy to check for any changes.
                            </span>
                            <h1>

                                Information We Collect and How We Collect It.
                            </h1>
                            <span>
                                We collect information from and about users of our Site and Vartalap Software: (i) directly from you when you provide it to us; and (ii) automatically when you use the Site or Vartalap Software, including when Vartalap Customer Visitors engage in communication using the Vartalap Software through Vartalap Customer websites
                            </span>
                            <h1>

                                Information You Provide to Us.
                            </h1>
                            <span>
                                When you access or use the Site or Vartalap Software, we may ask you to provide personally identifiable information, such as name, postal address, email address, telephone number or any other information we might collect that is defined as personal or personally identifiable information under applicable law (“Personal Information”). This information may include records and copies of our correspondence (including transcripts of chats, email addresses and phone numbers), your responses to surveys; details on transactions you carry out through the Site or Vartalap Software; or your search queries on the Site or Vartalap Software. In addition, we may maintain transcripts of chats, emails, and other written communications conducted between Vartalap Customers and their Vartalap Customer Visitors. We may, in accordance with applicable law, record and maintain recordings of voice or other communications between Vartalap Customers and their Vartalap Customer Visitors and/or between you and Vartalap
                            </span>
                            <h1>

                                Information Delivered to us by Vartalap Customers and by Third Parties.
                            </h1>
                            <span>
                                Vartalap Customers may provide data (including data related to the Vartalap  Customer Visitors to their websites) to us in order for us to combine such data with our data for purposes of analyzing such combined data and providing feedback and running reports related to such combined data on behalf of our Vartalap Customers. We maintain records of all such analyses, feedback, and reports. We may, on behalf of our Vartalap Customers, obtain from a third party data source commercially available information related to Vartalap Customer Visitors. We maintain copies of such information. As mentioned in this Privacy Policy, we are not responsible for the privacy practices of any of our Vartalap Customers or any third party.
                            </span>
                            <h1>

                                Information Collected Automatically and Tracking.
                            </h1>
                            <span>
                                When you download, access or use the Site or Vartalap Software, they may automatically collect certain information about your equipment, browsing actions, and patterns: (i) usage details (including traffic data, location data, communication data and the resources you access and use through the Site and Vartalap Software); (ii) device information (including IP address, operating system, browser type, hardware ID, mobile network information and the device’s telephone number); (iii) stored information (including metadata and other information associated with the files stored on your device, such as photographs, audio and video clips, personal contacts and address book information); and (iv) location information (including real-time information about the location of your device). If you do not want us to collect this information, do not access or use the Site or Vartalap Software, or, if you have already accessed or used the Site or Vartalap Software, stop doing so immediately and delete any Vartalap Software from your device.
                            </span>
                            <span>

                                The technologies we use for automatic information collection may include cookies (small files sent to and stored on the computer or device you use to access or use our Site or Vartalap Software) and other technologies such as pixel tags (small graphic images embedded in an email or website for purposes of tracking activities). We also may use these technologies to collect information about your activities over time and across third-party websites, apps or other online services (behavioral tracking).
                            </span>
                            <span>

                                Do Not Track (DNT) signals are signals sent through a browser informing us that you do not want to be tracked. We do not assign any meaning to DNT signals.
                            </span>
                            <h1>

                                Third-Party Information Collection.
                            </h1>
                            <span>
                                When you access or use the Site or Vartalap Software, or access their content, certain third parties, such as advertisers, analytics companies, your mobile device manufacturer, your mobile service provider and (if you are an Vartalap Customer Visitor) Vartalap Customers may use automatic information collection technologies to collect information about you, your computer or your device. The information these third parties collect may be associated with your personal information or your online or mobile activities over time and across different websites, apps and other online services websites. Such third parties may use this information to provide you with interest-based (behavioral) advertising or other targeted content. We do not control third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
                            </span>
                            <h1>

                                How We Use Your Information.
                            </h1>
                            <span>
                                We use the information that we collect about you or that you provide to us, including any personal information, to (i) provide you with the Site and Vartalap Software and their contents, and any other information, products or services that you request from us; (ii) provide feedback and run reports related to the browsing, purchasing, and online behavior and activities of Vartalap Customers’ Visitors; (iii) perform or deliver a service to Vartalap Customers and/or provide maintenance or support services for Vartalap Customers; (iv) undertake training and quality assurance; (v) fulfill any other purpose for which you provide it; (vi) give Vartalap Customers notices about your account/subscription, including expiration and renewal notices; (vii) carry out our obligations and enforce our rights, including for billing and collection; (viii) notify Vartalap Customers when updates are available, and of changes to any products or services we offer or provide through the Site and Vartalap Software; (ix) to allow you to participate in interactive features on our Site.
                            </span>
                            <span>

                                The usage information we collect helps us improve our Site and Vartalap Software and deliver a better and more personalized experience by enabling us to estimate our audience size and usage patterns, store information about your preferences, customize our Site and Vartalap Software according to your individual interests, speed up your searches, and recognize you when you use the Site or Vartalap Software. The information we collect about our Vartalap Customer Visitors enables us to offer Vartalap Customers a Software which they use to communicate with, interact with, and deliver content to, Vartalap Customer Visitors to their websites.
                            </span>
                            <span>

                                We use the information we collect about your location to combine such data with our data for purposes of analyzing such combined data and providing feedback and running reports related to such combined data on behalf of our Vartalap Customers.
                            </span>
                            <h1>

                                Disclosure of Your Information.
                            </h1>
                            <span>
                                We may disclose aggregated information about you without restriction. Also, we may disclose your personal information to: (i) our subsidiaries and affiliates; (ii) contractors, service providers and other third parties we use to support our business and who are contractually bound to keep your personal information confidential and only use it for purposes for which we disclose it to them; (iii) to a buyer or other successor to all or substantially all of our assets, or the assets of any division related hereto, through merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer; (iv) to comply with any court order, law or legal process, including to respond to any government or regulatory request; (vi) to enforce our rights; or (vii) if we believe disclosure is necessary or appropriate to protect our rights, property, or safety, or those of others.
                            </span>
                            <h1>

                                Your Choices About Our Collection, Use and Disclosure of Your Information.
                            </h1>
                            <span>
                                We strive to provide you with choices about your personal information. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. You may be able to choose whether or not to allow the Site or Vartalap Software to collect and use real-time information about your device’s location through the device’s settings. However, if you disable or refuse cookies or block the use of other tracking technologies, some portions of the Site or Vartalap Software may be inaccessible or not function properly.
                            </span>
                            <span>

                                If you are a Vartalap Customer and do not want us to use your email address or other contact information for advertising purposes, you can opt out by adjusting your Vartalap account profile, selecting “unsubscribe” or emailing us directly. You can review and change your personal information by logging into the Site or Vartalap Software and visiting your account profile page.
                            </span>
                            <span>

                                We do not control Vartalap Customers’ or other third parties’ collection or use of your information for interest-based advertising; however, these third parties may provide you with choices relative to your personal information.
                            </span>
                            <h1>

                                Data Security
                            </h1>
                            <span>
                                We implement measures designed to protect your personal information from accidental loss or unauthorized access, use, alteration or disclosure. However, the safety and security of your information also depend on you. We urge you to protect your user information and password and to be careful about giving out information in public areas of the Site or Vartalap Software, which may be viewed by other users of the Site or Vartalap Software.
                            </span>
                            <span>

                                We implement measures designed to protect your personal information from accidental loss or unauthorized access, use, alteration or disclosure. However, the safety and security of your information also depend on you. We urge you to protect your user information and password and to be careful about giving out information in public areas of the Site or Vartalap Software, which may be viewed by other users of the Site or Vartalap Software.
                            </span>
                            <h1>

                                Grievance Redressal
                            </h1>
                            <span>
                                If you have any queries or concerns about Vartalap’s Privacy Policy, or other terms and conditions, you may write to us using the following contact details:
                            </span>
                            <span>

                                Grievance Officer: Arun Menon
                            </span>
                            <span>

                                Address: 1501, Europa E, Casabella Gold, Palava, Thane - 421 204
                            </span>
                            <span>

                                <a href="mailto:arun@vartalapai.com">arun@vartalapai.com</a>
                            </span>
                        </div>
                    </header>
                </div>
            </div>

        </div>
    );
}

export default Privacy;
