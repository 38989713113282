import React from 'react';

function Features() {
    return (
        <div>
            <div className="subpage-header ">
                <div className="wrapper">
                    <section>
                        <div className="cust-content">
                            <p>
                            VBOT architecture consists of a combination of large language models orchestrated to do different tasks required complete a turn of user. These models are further fine tuned for each domain to understand domain related nuances and language.                            </p>
                        </div>
                        <div className="cust-image">
                            <img src="images/features-head.jpg" alt="Vartalap Features" />
                        </div>
                    </section>
                </div>
            </div>

            <div className="solutions small-grid">
                <div className="wrapper">
                    <section>
                        <div className="content fv">
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/scholar-icon.png" alt="" />
                                </div>
                                <p>BOT which understands contexts and can continuously train in production</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/ml-icon.png" alt="" />
                                </div>
                                <p>AI based understanding of customers inputs</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/rocket-icon.png" alt="" />
                                </div>
                                <p>Drive conversations with customers proactively and reactively.</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/grid-icon.png" alt="" />
                                </div>
                                <p>No Code BOT Builder</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/stack-icon.png" alt="" />
                                </div>
                                <p>Prebuilt components that behave as per merchant configuration</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/target-icon.png" alt="" />
                                </div>
                                <p>BOT based marketing campaigns and interventions</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/users-icon.png" alt="" />
                                </div>
                                <p>Built in Integration with multiple technologies partners  for easy implementation</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/note-icon.png" alt="" />
                                </div>
                                <p>Rich Reports BOT Performance and Revenue Reports</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/chat-icon.png" alt="" />
                                </div>
                                <p>Audience Creation based on customer conversations</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/globe-icon.png" alt="" />
                                </div>
                                <p>Multichannel support including whatsapp, slack, web as well auto responses on Social platforms</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div className="subpage-header ">
                <div className="wrapper">
                    <section>
                        <div className="cust-content">
                            <h1>
                            GTM - Deployment And Integration
                            </h1>
                            <p>
                            Lorem ipsum dolor sit amet consectetur. Leo ipsum cursus morbi quis urna vitae senectus. Imperdiet augue nunc viverra mauris auctor duis. Habitant nulla sed ut pellentesque vestibulum quis.
                            </p>
                        </div>
                        <div className="cust-image">
                            <img src="images/campaign.jpg" alt="Vartalap Solutions" />
                        </div>
                    </section>
                </div>
            </div>

            <div className="solutions mid-grid">
                <div className="wrapper">
                    <section>
                        <div className="content fv">
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/cloud-icon.png" alt="" />
                                </div>
                                <p>VBOT is fully deployable on both on-premise and cloud environments</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/puzzle-icon.png" alt="" />
                                </div>
                                <p>VBOT easily integrates into your existing products of Commerce, CRM, Automation etc</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/customise-icon.png" alt="" />
                                </div>
                                <p>VBOT platform is highly customizable and configurable</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/expand-icon.png" alt="" />
                                </div>
                                <p>VBOT can easily scale to support millions of customers and interactions</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/shield-icon.png" alt="" />
                                </div>
                                <p>VBOT is a highly secure enterprise grade platform with support for various protocols of authorization, authentication and encryption</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Features;