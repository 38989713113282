import React from 'react';

function Terms() {

    return (
        <div>
            <div className="header-container">
                <div className="wrapper">
                    <header>
                        <div className="hero-content">
                            <p> THIS TERMS OF SERVICE IS A LEGAL AGREEMENT BETWEEN <strong>VARTALAP</strong> (“Vartalap”, “WE”,
                                “OUR”, “US”) AND <strong>YOU </strong>(“YOU”), REGARDING THE SERVICES PROVIDED TO YOU THROUGH
                                KEY COMPONENTS (“SITE”). READ IT CAREFULLY. BY USING AND/OR PAYING FOR THE SERVICE(S) SET OUT
                                HEREIN YOU AGREE TO BE BOUND BY ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, WHICH INCLUDES
                                OUR PRIVACY POLICY INCORPORATED HEREIN BY REFERENCE. BY USING THE SITE OR SERVICES YOU REPRESENT
                                AND WARRANT THAT YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH VARTALAP AI PRIVATE LIMITED. IF
                                YOU USE THE SERVICES ON BEHALF OF YOUR EMPLOYER, YOU WARRANT THAT YOU ARE AUTHORIZED TO ENTER
                                INTO LEGALLY BINDING CONTRACTS ON BEHALF OF YOUR EMPLOYER AND THE SAME RIGHTS, LIMITATIONS AND
                                RESTRICTIONS APPLY TO YOUR EMPLOYER. WE ARE RELYING UPON YOUR REPRESENTATION THAT YOU MAY BIND
                                YOUR EMPLOYER TO THE TERMS OF THIS AGREEMENT. IF YOU AND/OR YOUR EMPLOYER DO NOT AGREE TO THE
                                TERMS OF THIS AGREEMENT, OR IF YOU ARE NOT AUTHORIZED TO ENTER INTO LEGALLY BINDING CONTRACTS ON
                                BEHALF OF YOUR EMPLOYER, YOU MUST NOT USE THE SERVICE(S) OR MAKE ANY PAYMENT.</p>
                            <p> We may revise and update these Terms of Service from time to time at our sole discretion. All
                                changes are effective immediately when posted and apply to all access to and use of the Site and
                                Services thereafter. For all such revisions, we will notify you of the applicable changes via
                                your email address we have on files. If you have opted out of email updates and/or have
                                unsubscribed from our update list, it is your responsibility to check these Terms of Service
                                periodically for changes, as these changes are binding on you. Your continued use of the Site or
                                Services following the posting of changes to these Terms of Service means that you accept and
                                agree to such changes</p>
                            <p> <span className="text-black">NOTICE REGARDING DISPUTE RESOLUTION: These Terms of Service contain
                                provisions that govern the resolution of claims between you and <strong>Vartalap
                                    Technologies</strong>. They also include an agreement to arbitrate, which will, with
                                limited exceptions, require you to submit claims you have against us to binding and final
                                arbitration. Unless you opt out, you will only be able to pursue claims against Vartalap on
                                an individual basis, not as part of any className or representative action or proceeding, and
                                you will only be permitted to seek relief (including monetary, injunctive, and declaratory
                                relief) on an individual basis.</span></p>
                            <h2>Grant of License to Access and Use Service.</h2>
                            <p> non-sublicensable, non-assignable, royalty-free, and worldwide license to access Vartalap hereby
                                grants to you, including all your Authorized Users, a non-exclusive, and use the Vartalap
                                platform (the “Service”) solely for your business operations.</p>
                            <h2>Subscription Fees.<br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <p> You shall pay Vartalap a required subscription fee (the “Subscription Fee”) for the
                                        Service provided. Any and all payments made by you to Vartalap for the Services are
                                        final and non-refundable.</p>
                                </li>
                                <li>
                                    <p> Subscription Fee shall be paid within 5 business days from the date of invoice.</p>
                                </li>
                            </ul>
                            <h2>Service Levels<br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Applicable Levels<br /></h3>
                                    <p> Vartalap shall provide the Service to you with a Service Availability of at least 99.9%
                                        during the Subscription Term.</p>
                                </li>
                                <li>
                                    <h3>System Maintenance<br /></h3>
                                    <p> Vartalap may take the Service offline for scheduled maintenance given that it provides
                                        you the schedule for such maintenance in writing (though this scheduled maintenance time
                                        will not count as Service Availability).</p>
                                </li>
                                <li>
                                    <h3>Service Availability Definition<br /></h3>
                                    <ul className="terms-list">
                                        <li>
                                            <h4>Percentage of Minutes per Month<br /></h4>
                                            <p> “Service Availability” means the percentage of minutes in a month that the key
                                                components of the Service are operational.</p>
                                        </li>
                                        <li>
                                            <h4>Not Included in “Service Availability<br /></h4>
                                            <p> “Service Availability” will not include any minutes of downtime resulting from
                                            </p>
                                            <ul className="terms-list">
                                                <li>
                                                    <p> scheduled maintenance,</p>
                                                </li>
                                                <li>
                                                    <p> events of force majeure,</p>
                                                </li>
                                                <li>
                                                    <p> malicious attacks on the system,</p>
                                                </li>
                                                <li>
                                                    <p> issues associated with your computing devices, local area networks or
                                                        internet service provider connections, or</p>
                                                </li>
                                                <li>
                                                    <p> Vartalap’s inability to deliver services because of your acts or
                                                        omissions.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h2>Privacy</h2>
                            <p> We value your privacy and understand your privacy concerns. Please review our Privacy Policy,
                                which also governs your access to and use of the Site and Services, so that you may understand
                                our privacy practices. All information we collect is subject to our Privacy Policy, and by using
                                the Site or Services you consent to all actions taken by us with respect to your information in
                                compliance with the Privacy Policy.<br />‍<br />If you are a customer of Vartalap Technologies,
                                you agree that prior to implementing the services provided on your website, you shall ensure
                                that your website privacy policy (a) fully discloses to users of your website (your customers)
                                how you treat and use information collected through our service implemented on your website; (b)
                                states that the chat option on your website is provided through a third party vendor, Vartalap,
                                who collects, uses, stores, shares, discloses and protects Vartalap’ Customer Visitors’
                                information obtained by Inteliticks in connection with providing the chat option on your website
                                in accordance with the Vartalap Privacy Policy; and (c) includes a link to the Vartalap Privacy
                                Policy.</p>
                            <h2>Data Privacy and Safeguard</h2>
                            <p> Vartalap’ Data Processing Agreement is a separate document and part of this agreement. </p>
                            <h2>Representations <br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Mutual Representations<br /></h3>
                                    <ul className="terms-list">
                                        <li>
                                            <h4>Existence<br /></h4>
                                            <p> The parties are corporations incorporated and existing under the laws of the
                                                jurisdictions of their respective incorporation.</p>
                                        </li>
                                        <li>
                                            <h4>Authority and Capacity<br /></h4>
                                            <p> The parties have the authority and capacity to enter into this agreement.</p>
                                        </li>
                                        <li>
                                            <h4>Execution and Delivery<br /></h4>
                                            <p> The parties have duly executed and delivered this agreement.</p>
                                        </li>
                                        <li>
                                            <h4>Enforceability<br /></h4>
                                            <p> This agreement constitutes a legal, valid, and binding obligation, enforceable
                                                against the parties according to its terms.</p>
                                        </li>
                                        <li>
                                            <h4>No Conflicts<br /></h4>
                                            <p> Neither party is under any restriction or obligation that might affect the
                                                party’s performance of its obligations under this agreement.</p>
                                        </li>
                                        <li>
                                            <h4>No Breach<br /></h4>
                                            <p> Neither party’s execution, delivery, or performance of its obligations under
                                                this agreement will breach or result in a default under</p>
                                            <ul className="terms-list">
                                                <li>
                                                    <p> its articles, bylaws, or any unanimous shareholders agreement,</p>
                                                </li>
                                                <li>
                                                    <p> any Law to which it is subject,</p>
                                                </li>
                                                <li>
                                                    <p> any judgment, Order, or decree of any Governmental Authority to which it
                                                        is subject, or</p>
                                                </li>
                                                <li>
                                                    <p> any agreement to which it is a party or by which it is bound.</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h4>Permits, Consents, and Other Authorizations<br /></h4>
                                            <p> any agreement to which it is a party or by which it is bound.</p>
                                            <ul className="terms-list">
                                                <li>
                                                    <p> own, lease, and operate its properties, and </p>
                                                </li>
                                                <li>
                                                    <p> conduct its business as it is now carried on.</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h4>No Disputes or Proceedings.<br /></h4>
                                            <p> There are no Legal Proceedings pending, threatened, or foreseeable against
                                                either party, which would affect that party’s ability to complete its
                                                obligations under this agreement.</p>
                                        </li>
                                        <li>
                                            <h4>No Bankruptcy<br /></h4>
                                            <p> Neither party has taken or authorized any proceedings related to that party’s
                                                bankruptcy, insolvency, liquidation, dissolution, or winding up.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Vartalap’ Representation<br /></h3>
                                    <ul className="terms-list">
                                        <li>
                                            <h4>Ownership<br /></h4>
                                            <p> Vartalap is the exclusive legal owner of the Service, including all Intellectual
                                                Property included in the Service and granted under the Service.</p>
                                        </li>
                                        <li>
                                            <h4>Status of Licensed Intellectual Property<br /></h4>
                                            <p> Vartalap has properly registered and maintained all Intellectual Property
                                                included in the Service and paid all applicable maintenance and renewal fees.
                                            </p>
                                        </li>
                                        <li>
                                            <h4>No Conflicting Grant<br /></h4>
                                            <p> Vartalap has not granted and is not obligated to grant any license to a third
                                                party that would conflict with the Service.</p>
                                        </li>
                                        <li>
                                            <h4>No Infringement<br /></h4>
                                            <p> The Service does not infringe the Intellectual Property rights or other
                                                proprietary rights of any third party.</p>
                                        </li>
                                        <li>
                                            <h4>No Third Party Infringement.<br /></h4>
                                            <p> To Vartalap’ Knowledge, no third party is infringing on the Service.</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h2>User Obligations</h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Hardware Obligations<br /></h3>
                                    <p> you shall be responsible for</p>
                                    <ul className="terms-list">
                                        <li>
                                            <p> obtaining and maintaining all computer hardware, software, and communications
                                                equipment needed to internally access the Service</p>
                                        </li>
                                        <li>
                                            <p> conduct its business as it is now carried on.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Anti-Virus Obligations<br /></h3>
                                    <p> you shall be responsible for implementing, maintaining, and updating all necessary and
                                        proper procedures and software for safeguarding against computer infection, viruses,
                                        worms, Trojan horses, and other code that manifest contaminating or destructive
                                        properties (collectively “Viruses”).</p>
                                </li>
                                <li>
                                    <h3>YOUR Use of Services<br /></h3>
                                    <p> you shall</p>
                                    <ul className="terms-list">
                                        <li>
                                            <p> abide by all local and international Laws and regulations applicable to its use
                                                of the Service,</p>
                                        </li>
                                        <li>
                                            <p> use the Service only for legal purposes, and</p>
                                        </li>
                                        <li>
                                            <p> comply with all regulations, policies, and procedures of networks as per the
                                                Terms of Service</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h2>Restricted Uses</h2>
                            <ul className="terms-list">
                                <li>
                                    <p> you will not<br /></p>
                                    <ul className="terms-list">
                                        <li>
                                            <p> modify, disassemble, decompile or reverse engineer the Service,</p>
                                        </li>
                                        <li>
                                            <p> probe, scan, test the vulnerability of, or circumvent any security mechanisms
                                                used by, the sites, servers, or networks connected to the Service,</p>
                                        </li>
                                        <li>
                                            <p> take any action that imposes an unreasonable or disproportionately large load on
                                                the sites, servers, or networks connected to the Service,</p>
                                        </li>
                                        <li>
                                            <p> copy or reproduce the Service,</p>
                                        </li>
                                        <li>
                                            <p> access or use any other clients’ or their users’ data through the Service,</p>
                                        </li>
                                        <li>
                                            <p> maliciously reduce or impair the accessibility of the Service,</p>
                                        </li>
                                        <li>
                                            <p> use the service to post, promote, or transmit any unlawful, harassing, libelous,
                                                abusive, threatening, harmful, hateful, or otherwise objectionable material, or
                                            </p>
                                        </li>
                                        <li>
                                            <p> transmit or post any material that encourages conduct that could constitute a
                                                criminal offense or give rise to civil liability.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p> Restrictions in section 8.1 shall apply only to your employees and Authorized Users.</p>
                                </li>
                                <li>
                                    <p> Restrictions in section 8.1 shall not apply to website visitors of your websites who are
                                        not your employees and Authorized Users.</p>
                                </li>
                            </ul>
                            <h2>Confidentiality</h2>
                            <ul className="terms-list">
                                <li>
                                    <p> Each Party acknowledges that any information relating to the business, products,
                                        practices, customers and such other information identified by the other party as
                                        confidential at or prior to such disclosure (“Confidential Information”) disclosed to it
                                        by the other party (unless pursuant to another written Agreement signed by the
                                        authorized representatives of the parties) is for the purpose set forth in this
                                        Agreement and agrees to maintain such material, information, or disclosures in
                                        confidence. This provision shall not apply to any material, information or other
                                        disclosure.<br /></p>
                                    <ul className="terms-list">
                                        <li>
                                            <p> that can be demonstrated to have been in the public domain prior to its
                                                disclosure, or</p>
                                        </li>
                                        <li>
                                            <p> that can be demonstrated to have been in the other party’s possession prior to
                                                its disclosure; or</p>
                                        </li>
                                        <li>
                                            <p> to the extent supplied, without restrictions on use, by a third party who is
                                                lawfully in possession of such material, information or other disclosure and is
                                                entitled to supply it.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p> Each Party shall maintain the Confidential Information as strictly confidential and
                                        shall use the Confidential Information only in connection with the proper discharge and
                                        performance of its obligations to the other party</p>
                                </li>
                            </ul>
                            <h2>Intellectual Property Rights<br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <p> Each Party shall retain all right, title and interest in its patents, copyrights,
                                        trademarks, trade name, trade dress, service marks and trade secrets and/or creation,
                                        model, data, flowchart, drawing, design, diagram, table, artwork, innovation or any
                                        other information or material (“Intellectual Property Rights”). No interest whatsoever
                                        in the other Party’s Intellectual Property Rights is granted by this Agreement and the
                                        use of any Intellectual Property Right permitted to one Party by the other Party shall
                                        be strict as mutually agreed by the Parties.<br /></p>
                                </li>
                                <li>
                                    <p> Neither Party shall use the Intellectual Property of the other Party in any manner
                                        whatsoever without the prior written consent of the other Party or as provided
                                        hereunder.</p>
                                </li>
                            </ul>
                            <h2>Subscription Term<br /></h2>
                            <p> This Agreement shall commence on the Effective Date and shall remain in effect for the
                                Subscription Period unless terminated earlier by either party pursuant to this Agreement.<br />
                            </p>
                            <h2>Termination<br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Termination on Notice<br /></h3>
                                    <p> you may terminate this agreement for any reason on 7 business days’ notice to the other
                                        party.<br /></p>
                                </li>
                                <li>
                                    <h3>Termination for Material Breach<br /></h3>
                                    <p> If either party materially breaches any of its duties or obligations under this
                                        Agreement, and such breach is not cured within thirty (30) calendar days of the
                                        non-breaching party providing the breaching party of written notice of the breach, the
                                        non-breaching party may terminate this Agreement.<br /></p>
                                </li>
                                <li>
                                    <h3>Termination for Failure to Pay<br /></h3>
                                    <p> Vartalap may terminate this agreement with immediate effect by delivering notice of
                                        termination to you if you fail to pay the Subscription Fee within 7 business days after
                                        written notice.<br /></p>
                                </li>
                            </ul>
                            <h2>Effect of Termination<br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Pay Outstanding Amounts<br /></h3>
                                    <p> You shall immediately pay to Vartalap all amounts outstanding as of the date of, and any
                                        amounts outstanding as a result of, termination.<br /></p>
                                </li>
                                <li>
                                    <h3>Discontinuance of Use<br /></h3>
                                    <p> You shall cease all use of the Service upon the effective date of the termination.<br />
                                    </p>
                                </li>
                                <li>
                                    <h3>Recovery of Data<br /></h3>
                                    <p> You will have 30 calendar days from the date of termination to retrieve any of the data
                                        that you wish to keep.<br /></p>
                                </li>
                            </ul>
                            <h2>Limited Warranty<br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Limited Warranty<br /></h3>
                                    <p> Vartalap warrants, for your benefit only, that each Service will operate in substantial
                                        conformity with the applicable documentation. Vartalap’ sole liability (and your sole
                                        and exclusive remedy) for any breach of this warranty will be, at no charge to you, for
                                        Vartalap to use commercially reasonable efforts to correct the reported non-conformity,
                                        or if Vartalap determines such remedy to be impracticable, either party may terminate
                                        the applicable Subscription Term and you will receive as its sole remedy a refund of any
                                        fees you have pre-paid for use of such Service for the terminated portion of the
                                        applicable Term. The limited warranty set forth in this section will not apply:<br />
                                    </p>
                                    <ul className="terms-list">
                                        <li>
                                            <p> unless you make a claim within thirty (30) days of the date on which you first
                                                noticed the non-conformity, <br /></p>
                                        </li>
                                        <li>
                                            <p> if the error was caused by misuse, unauthorized modifications or third-party
                                                hardware, software or services, or </p>
                                        </li>
                                        <li>
                                            <p> to use provided on a no-charge, trial or evaluation basis.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Warranty Disclaimer<br /></h3>
                                    <p> Except for the limited warranty in section 17.a, all services and professional services
                                        are provided “as is”. Neither Vartalap nor its suppliers make any other warranties,
                                        express or implied, statutory or otherwise, including but not limited to the warranties
                                        of merchantability, title, fitness for a particular purpose or non-infringement.
                                        Vartalap does not warrant that your use of the service will be uninterrupted or
                                        error-free, nor does Vartalap warrant that it will review your data for accuracy or that
                                        it will preserve or maintain your data without loss or corruption. Vartalap shall not be
                                        liable for the results of any communications sent or any communications that were failed
                                        to be sent using the services. Vartalap shall not be liable for delays, interruptions,
                                        service failures or other problems inherent in the use of the internet and electronic
                                        communications, third-party platforms or other systems outside the reasonable control of
                                        Vartalap. You may have other statutory rights, but the duration of statutorily required
                                        warranties, if any, shall be limited to the shortest period permitted by law.<br /></p>
                                </li>
                            </ul>
                            <h2>Indemnification<br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Indemnification by Vartalap.<br /></h3>
                                    <ul className="terms-list">
                                        <li>
                                            <h4>Indemnification for Infringement Claims<br /></h4>
                                            <p> Vartalap (as an indemnifying party) shall indemnify you (as an indemnified
                                                party) against all losses and expenses arising out of any proceeding<br /></p>
                                            <ul className="terms-list">
                                                <li>
                                                    <p> brought by a third party, and<br /></p>
                                                </li>
                                                <li>
                                                    <p> arising out of a claim that the Service infringes the third party’s
                                                        Intellectual Property rights</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h4>Qualifications for Indemnification<br /></h4>
                                            <p> Vartalap will be required to indemnify you only if<br /></p>
                                            <ul className="terms-list">
                                                <li>
                                                    <p> your use of the Service complies with this agreement and all
                                                        documentation related to the Service,<br /></p>
                                                </li>
                                                <li>
                                                    <p> the infringement was not caused by you modifying or altering the Service
                                                        or documentation related to the Service unless Vartalap consented to the
                                                        modification or alteration in writing, and</p>
                                                </li>
                                                <li>
                                                    <p> the infringement was not caused by you combining the Service with
                                                        products not supplied by Vartalap unless Vartalap consented to the
                                                        combination in writing.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Mutual Indemnification<br /></h3>
                                    <p> Each party (as an indemnifying party) shall indemnify the other (as an indemnified
                                        party) against all losses arising out of any proceeding<br /></p>
                                    <ul className="terms-list">
                                        <li>
                                            <p> brought by either a third party or an indemnified party, and<br /></p>
                                        </li>
                                        <li>
                                            <p> arising out of the indemnifying party’s willful misconduct or gross
                                                negligence.<br /></p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <h3>Notice and Failure to Notify<br /></h3>
                                    <ul className="terms-list">
                                        <li>
                                            <h4>Notice and Failure to Notify<br /></h4>
                                            <p> Before bringing a claim for indemnification, the indemnified party shall<br />
                                            </p>
                                            <ul className="terms-list">
                                                <li>
                                                    <p> notify the indemnifying party of the indemnifiable proceeding, and<br />
                                                    </p>
                                                </li>
                                                <li>
                                                    <p> deliver to the indemnifying party all legal pleadings and other
                                                        documents reasonably necessary to indemnify or defend the indemnifiable
                                                        proceeding.<br /></p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h4>Failure to Notify<br /></h4>
                                            <p> If the indemnified party fails to notify the indemnifying party of the
                                                indemnifiable proceeding, the indemnifying party will be relieved of its
                                                indemnification obligations to the extent it was prejudiced by the indemnified
                                                party’s failure.<br /></p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h2>Exclusive Remedy</h2>
                            <p> The parties’ right to indemnification is the exclusive remedy available in connection with the
                                indemnifiable proceedings described in this section.</p>
                            <h2>Limitation on Liability<br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Mutual Limit on Liability<br /></h3>
                                    <p> Neither party will be liable for breach-of-contract damages suffered by the other party
                                        that are remote or speculative, or that could not have reasonably been foreseen on entry
                                        into this agreement.<br /></p>
                                </li>
                                <li>
                                    <h3>Maximum Liability<br /></h3>
                                    <p> Vartalap’ liability under this agreement will not exceed the fees paid by you under this
                                        agreement during the 12 months preceding the date upon which the related claim
                                        arose.<br /></p>
                                </li>
                            </ul>
                            <h2>Definitions<br /></h2>
                            <p> “Authorized Users” means the list of Persons authorized to use the Services under this
                                agreement.<br />“Business Day” means a day other than a Saturday, Sunday, or any other day on
                                which the principal banks located in New Delhi, India is not open for business.<br />“Data”
                                means all of the data you create with or uses with the Service, or otherwise related to your use
                                of the Services.<br />“Effective Date” means the date which You commence using the
                                Services.<br />“Governmental Authority” means<br /></p>
                            <ul className="terms-list alfa">
                                <li>
                                    <p> any federal, central, state, local, or foreign government, and any political subdivision
                                        of any of them,</p>
                                </li>
                                <li>
                                    <p> any agency or instrumentality of any such government or politic</p>
                                </li>
                                <li>
                                    <p> any self-regulated organization or other non-governmental regulatory authority or
                                        quasi-governmental authority (to the extent that its rules, regulations or orders have
                                        the force of law), and</p>
                                </li>
                                <li>
                                    <p> any arbitrator, court or tribunal of competent jurisdiction</p>
                                </li>
                            </ul>
                            <p> “Intellectual Property” means any and all of the following in any jurisdiction throughout the
                                world<br /></p>
                            <ul className="terms-list alfa">
                                <li>
                                    <p> trademarks and service marks, including all applications and registrations, and the
                                        goodwill connected with the use of and symbolized by the foregoing,</p>
                                </li>
                                <li>
                                    <p> copyrights, including all applications and registrations related to the foregoing,</p>
                                </li>
                                <li>
                                    <p> trade secrets and confidential know-how,</p>
                                </li>
                                <li>
                                    <p> patents and patent applications,</p>
                                </li>
                                <li>
                                    <p> websites and internet domain name registrations, and</p>
                                </li>
                                <li>
                                    <p> other intellectual property and related proprietary rights, interests and protections
                                        (including all rights to sue and recover and retain damages, costs and attorneys’ fees
                                        for past, present, and future infringement, and any other rights relating to any of the
                                        foregoing).</p>
                                </li>
                            </ul>
                            <p> “Law” means</p>
                            <ul className="terms-list alfa">
                                <li>
                                    <p> any law (including the common law), statute, bylaw, rule, regulation, order, ordinance,
                                        treaty, decree, judgment, and</p>
                                </li>
                                <li>
                                    <p> any official directive, protocol, code, guideline, notice, approval, order, policy, or
                                        other requirements of any Governmental Authority having the force of law.</p>
                                </li>
                            </ul>
                            <p> “Legal Proceeding” means any claim, investigation, hearing, legal action, or other legal,
                                administrative, arbitral, or similar proceeding, whether civil or criminal (including any appeal
                                or review of any of the foregoing).<br />“Subscription Plan” means the Vartalap-approved service
                                usage plan that YOU has agreed and subscribed to. <br />“Key components” means all the
                                functionalities of the admin console at <a href="https://vbot.vartalapai.com/"
                                    className="banner-link">https://vbot.vartalapai.com</a> and Vartalap chat widget in the website
                                pages of Your website pages where it is installed and enabled.<br />“Permits” means all material
                                licenses, franchises, permits, certificates, approvals, and authorizations, from Governmental
                                Authorities necessary for the ownership and operation of the party’s business.</p>
                            <p> “Person” includes</p>
                            <ul className="terms-list alfa">
                                <li>
                                    <p> any corporation, company, limited liability company, partnership, Governmental
                                        Authority, joint venture, fund, trust, association, syndicate, organization, or other
                                        entity or group of persons, whether incorporated or not, and</p>
                                </li>
                                <li>
                                    <p> any individual.</p>
                                </li>
                            </ul>
                            <p> “Service” is defined in section 1<br />“Subscription Fee” is defined in section 2.<br />“Service
                                Availability” is defined in section 3.3.</p>
                            <p> “Taxes” includes all taxes, assessments, charges, duties, fees, levies, and other charges of a
                                Governmental Authority, including income, franchise, capital stock, real property, personal
                                property, tangible, withholding, employment, payroll, social security, social contribution,
                                unemployment compensation, disability, transfer, sales, use, excise, gross receipts, value-added
                                and all other taxes of any kind for which a party may have any liability imposed by any
                                Governmental Authority, whether disputed or not, any related charges, interest or penalties
                                imposed by any Governmental Authority, and any liability for any other person as a transferee or
                                successor by Law, contract or otherwise.</p>
                            <p> “Term” is defined in section 11.<br />“Viruses” is defined in section 10.</p>
                            <h2>General Provisions<br /></h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Entire Agreement<br /></h3>
                                    <p> The parties intend that this agreement, together with all attachments, schedules,
                                        exhibits, and other documents that both are referenced in this agreement and refer to
                                        this agreement,<br /></p>
                                    <ul className="terms-list">
                                        <li>
                                            <p> represent the final expression of the parties’ intent relating to the subject
                                                matter of this agreement,<br /></p>
                                        </li>
                                        <li>
                                            <p> contain all the terms the parties agreed to relate to the subject matter,
                                                and<br /></p>
                                        </li>
                                        <li>
                                            <p> replace all of the parties’ previous discussions, understandings, and agreements
                                                relating to the subject matter of this agreement.<br /></p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h2>Assignment</h2>
                            <p> Neither party may assign this agreement or any of their rights or obligations under this
                                agreement without the other party’s written consent.</p>
                            <h2>Notices</h2>
                            <p> The parties’ right to indemnification is the exclusive remedy available in connection with the
                                indemnifiable proceedings described in this section.</p>
                            <ul className="terms-list">
                                <li>
                                    <h3>Method of Notice<br /></h3>
                                    <p> The parties shall give all notices and communications between the parties in writing by
                                        (i) personal delivery, (ii) a nationally-recognized, next-day courier service, (iii)
                                        first-className registered or certified mail, postage prepaid, or (iv) electronic mail to
                                        the party’s address specified in this agreement, or to the address that a party has
                                        notified to be that party’s address for the purposes of this section.<br /></p>
                                </li>
                                <li>
                                    <h3>Receipt of Notice<br /></h3>
                                    <p> A notice given under this agreement will be effective on<br /></p>
                                    <ul className="terms-list">
                                        <li>
                                            <p> the other party’s receipt of it, or<br /></p>
                                        </li>
                                        <li>
                                            <p> if mailed, the earlier of the other party’s receipt of it and the fifth business
                                                day after mailing it. <br /></p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h2>Governing Law</h2>
                            <p> This agreement shall be governed, construed, and enforced in accordance with the laws of India,
                                without regard to its conflict of laws rules.</p>
                            <h2>Severability</h2>
                            <p> If any part of this agreement is declared unenforceable or invalid, the remainder will continue
                                to be valid and enforceable.</p>
                            <h2>Waiver</h2>
                            <ul className="terms-list">
                                <li>
                                    <h3>Affirmative Waivers<br /></h3>
                                    <p> Neither party’s failure or neglect to enforce any rights under this agreement will be
                                        deemed to be a waiver of that party’s rights.<br /></p>
                                </li>
                                <li>
                                    <h3>Written Waivers<br /></h3>
                                    <p> A waiver or extension is only effective if it is in writing and signed by the party
                                        granting it.<br /></p>
                                </li>
                                <li>
                                    <h3>No General Waivers<br /></h3>
                                    <p> A party’s failure or neglect to enforce any of its rights under this agreement will not
                                        be deemed to be a waiver of that or any other of its rights.<br /></p>
                                </li>
                                <li>
                                    <h3>No Course of Dealing<br /></h3>
                                    <p> No single or partial exercise of any right or remedy will preclude any other or further
                                        exercise of any right or remedy.<br /></p>
                                </li>
                            </ul>
                            <h2>Force Majeure</h2>
                            <p> Neither party will be liable for performance delays or for non-performance due to causes beyond
                                its reasonable control, except for payment obligations.</p>
                            <h2>Privacy Policy</h2>
                            <p> Vartalap’s Privacy Policy is a separate document and part of this agreement.<br /></p>

                        </div>
                    </header>
                </div>
            </div>

        </div>
    );
}

export default Terms;
