import React, {useState} from 'react';

function Header() {

    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        // console.log('Setting showNavbar ['+showNavbar+']');
      setShowNavbar(!showNavbar)
    }
  
    return (
        <div className="nav-container">
            <div className="wrapper">
                <nav>
                    <a href="/" className="logo">
                        <img src="images/logo.svg" alt="Vartalap AI Private Limtied" />
                    </a>

                    <ul className={`nav-items ${showNavbar && 'open'}`}>
                        <li>
                            <a href="/" className="active">Home</a>
                        </li>
                        <li>
                            <a href="solutions">Solutions</a>
                        </li>
                        <li>
                            <a href="features">VBOT Features</a>
                        </li>
                        {/* <li>
                            <a href="pricing">Pricing</a>
                        </li> */}
                        <li>
                            <a href="about-us">About Us</a>
                        </li>
                        {/* <li>
                        <a href="knowledge-hub.html">Knowledge Hub</a>
                    </li> */}
                    </ul>

                    <div className="menu-icon">
                        <label className="hamburger-menu"><input type="checkbox" id='menu-toggle'  onClick={handleShowNavbar}/></label>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;
