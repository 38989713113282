import React from 'react';

function Home() {
    return (
        <div>
            <div className="header-container">
                <div className="wrapper">
                    <header>
                        <div className="hero-content">
                            <h1>Chat bot built to increase your sales and improve customers experience and skyrocket satisfaction score</h1>
                            <span>Increase your convertion rate and help your customers to experience your product or service the best possible way by connecting our AI bot to any of your communication channels.</span>
                            <a href="/demo">See our demos</a>
                        </div>
                        <div className="hero-image">
                            <img src="images/dashboard.png" alt="vbot-dashboard-preview" />
                        </div>
                    </header>
                </div>
            </div>

            <div className="why-chatbot">
                <div className="wrapper">
                    <section>
                        <div className="cust-image">
                            <img src="images/client_ss.png" alt="Client screenshot" />
                        </div>
                        <div className="cust-content">
                            <h2>
                                Why to use a chatbot?
                            </h2>
                            <p>With the instant success of ChatGPT, chatbots and conversation AI has truly become mainstream.</p>
                            <p>
                                Nearly 40% of internet users worldwide prefer interacting with chatbots than virtual agents which is why by 2024, consumer retail spend via chatbots worldwide will reach $142 billion—up from just $2.8 billion in 2019. Similarly the adoption of chatbots could save the healthcare, banking, and retail sectors $11 billion annually by 2023.
                            </p><p>
                                Enterprises are now starting to spend a larger portion of their yearly tech budget build bots instead of traditional mobile app development.
                            </p>
                        </div>
                    </section>
                </div>
            </div>

            <div className="solutions">
                <div className="wrapper">
                    <section>
                        <h1>
                            But there are many problems and challenges to solve to arrive at a ChatBot solution that just works
                        </h1>
                        <div className="content">
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/db-icon.png" alt="" />
                                </div>
                                <p>Lack of dedicated resources, knowledge and budget to implement an optimum solution</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/book-icon.png" alt="" />
                                </div>
                                <p>Ability to continuously training the BOT so that it does not misinterpret the requests of the consumer</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/user-icon.png" alt="" />
                                </div>
                                <p>Very high Time to Market testing the patience of the business users and at the same time makes the goal post a moving target</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/ques-icon.png" alt="" />
                                </div>
                                <p>True conversational business BOT that talks and engages with the user and not just another navigation based BOT</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            <div className="solutions features">
                <div className="wrapper">
                    <section>
                        <h1>Vartalap's BOT builder platform VBOT was designed to solve these problems.</h1>
                        <div className="sub-content">
                            <div className="shot">
                                <img src="images/trainer.jpg" alt="" />
                            </div>
                            <div className="text">
                                <h2>Perpetual improvement</h2>
                                <p>VBOT is a BOT platform that not only understands the intent of the customer to drive conversation but at the same time learns from the success/failure of the historical conversations </p>
                            </div>
                        </div>
                        <div className="sub-content">
                            <div className="text">
                                <h2>Set in a few minutes</h2>
                                <p>VBOT platform has its own no-code BOT Builder which allows customers to generate, train and go live with their industry specific BOT in matter of minutes and not days and months</p>
                            </div>
                            <div className="shot">
                                <img src="images/setup.jpg" alt="" />
                            </div>
                        </div>
                        <div className="sub-content">
                            <div className="shot">
                                <img src="images/conversation.jpg" alt="" />
                            </div>
                            <div className="text">
                                <h2>Customizable</h2>
                                <p>VBOT platform is easily and very highly customizable</p>
                            </div>
                        </div>
                        <div className="sub-content">
                            <div className="text">
                                <h2>Integrate with any solution you need</h2>
                                <p>VBOT has integrations with major tech platforms enabling its customers to easily integrate VBOT with their backend platforms reducing integration and GTM timelines </p>
                            </div>
                            <div className="shot">
                                <img src="images/campaign.jpg" alt="" />
                            </div>
                        </div>
                        <div className="sub-content">
                            <div className="shot">
                                <img src="images/channel.jpg" alt="" />
                            </div>
                            <div className="text">
                                <h2>Multiple communication channels</h2>
                                <p>VBOT supports multiple channels like whatsapp, web, social media etc. off the shelf providing customers with a 360 BOT strategy</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Home;
