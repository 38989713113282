import React, { useState } from "react";

interface FormValues {
    name: string;
    email: string;
    phoneNumber: string;
    designation: string;
    industry: string;
}

const industryOptions = [
    'Fashion & Apparel',
    'Cosmetics',
    'Food & Beverages',
    'Health & Nutrition',
    'Personal Care',
    'Sports & Fitness',
    'Consumer Electronics',
    'Other'
];

const submitForm = async (formValues: FormValues) => {
    try {
        const response = await fetch('/mail/mailer.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formValues)
        });

        // Check if the response was successful
        if (response.ok) {
            return true;
        } else {
            // Handle the error or display a validation error message
            return false;
        }
    } catch (error) {
        // Handle network or other errors
        return false;
    }
};

const Demo: React.FC = () => {

    const [formValues, setFormValues] = useState<FormValues>({
        name: '',
        email: '',
        phoneNumber: '',
        designation: '',
        industry: ''
    });

    const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({
        name: false,
        email: false,
        phoneNumber: false,
        designation: false
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Regex patterns for validation
    const regexPatterns: { [key: string]: RegExp } = {
        name: /^[A-Za-z\s]+$/,
        email: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
        phoneNumber: /^\d{10}$/,
        designation: /^[A-Za-z\s.-]+$/
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;

        // Validate the value based on the field name
        const pattern = regexPatterns[name];
        const isValid = pattern ? pattern.test(value) : true;

        // console.log('['+ pattern +']=>['+value+']=['+isValid+']');
        setFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));

        setFieldErrors(prevState => ({
            ...prevState,
            [name]: !isValid
        }));

        if (isSubmitted) {
            setFieldErrors(prevState => ({
                ...prevState,
                [name]: false
            }));
        }
    };


    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Custom validation for all fields
        const isFormValid = Object.keys(formValues).every(fieldName => {
            const value = formValues[fieldName as keyof FormValues];
            const pattern = regexPatterns[fieldName];
            return pattern ? pattern.test(value) : true;
        });

        console.log('Is form valid ['+isFormValid+']');

        if (isFormValid) {

            (async () => {
                const success = await submitForm(formValues);

                if (success) {
                    setIsSubmitted(true);
                }

                setIsSubmitting(false);
            })();
        } else {
            setIsSubmitting(false);
        }
    };


    return (
        <div>
            <div className="header-container">
                <div className="wrapper">
                    <header>
                        {!isSubmitted ? (
                            <div className="hero-content not-found">

                                <h1>Book a Demo</h1>
                                <span>Please fill in the form below and we will reach out to you soon.</span>

                                <form onSubmit={handleSubmit} noValidate>
                                    <div className={fieldErrors.name ? 'has-error' : ''}>
                                        <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Full Name"
                                            value={formValues.name}
                                            onChange={handleChange}
                                            pattern="^[A-Za-z]+$"
                                            required
                                        />
                                    </div>
                                    <div className={fieldErrors.email ? 'has-error' : ''}>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Email Address"
                                            value={formValues.email}
                                            onChange={handleChange}
                                            pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
                                            required
                                        />
                                    </div>
                                    <div className={fieldErrors.phoneNumber ? 'has-error' : ''}>
                                        <input
                                            type="tel"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            placeholder="Phone Number"
                                            value={formValues.phoneNumber}
                                            onChange={handleChange}
                                            pattern="^\d{10}$"
                                            required
                                        />
                                    </div>
                                    <div className={fieldErrors.designation ? 'has-error' : ''}>
                                        <input
                                            type="text"
                                            id="designation"
                                            name="designation"
                                            placeholder="Designation"
                                            value={formValues.designation}
                                            onChange={handleChange}
                                            pattern="^[A-Za-z\s.-]+$"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <select
                                            id="industry"
                                            name="industry"
                                            value={formValues.industry}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">Industry</option>
                                            {industryOptions.map(option => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting' : 'Submit'}
                                    </button>
                                </form>
                            </div>

                        ) : (
                            <div className="hero-content not-found">
                                <h1>Thank you!</h1>
                                <p>We appreciate your interest. We shall get back to you soon.</p>
                            </div>
                        )}
                    </header>
                </div>
            </div>

        </div>
    );
}

export default Demo;
