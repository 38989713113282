import React from 'react';

function Solutions() {
    return (
        <div>
            <div className="subpage-header ">
                <div className="wrapper">
                    <section>
                        <div className="cust-content">
                            <h1>
                            Solutions for your business success
                            </h1>
                            <p>
                            Our strategy is to provide templates of multiple industries so that our customers can just choose a template suitable for their business and generate a BOT within hours if not minutes. The VBOT platform is industry and use case agnostic at the core but we also work with enterprise teams to build a custom BOT to support their own use case and industry.
                            </p>
                        </div>
                        <div className="cust-image">
                            <img src="images/solutions-head.jpg" alt="Vartalap Solutions" />
                        </div>
                    </section>
                </div>
            </div>

            <div className="solutions">
                <div className="wrapper">
                    <section>
                        <div className="content fv">
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/scholar-icon.png" alt="" />
                                </div>
                                <h2>Knowledge Discovery</h2>
                                <p>Making the life of the customer easy to understand brand, categories of products, product in each category, product details, various terms and conditions, company policies and any other Information required by the customer during purchase, post purchase and post delivery</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/tab-icon.png" alt="" />
                                </div>
                                <h2>Order Placement</h2>
                                <p>Providing ability to customers to complete the order placement directly on the BOT applying discounts available and then syncing the order the ecommerce platform customer uses</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/task-icon.png" alt="" />
                                </div>
                                <h2>Order Fulfillment</h2>
                                <p>Providing the customer with the flows to track and manage the order from confirmation to delivery. This includes order tracking, order delivery rescheduling, pre-paying the order to avail extra discounts or canceling the order with integration with the logistics and ecommerce platform for syncing any updates.</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/cust-support-icon.png" alt="" />
                                </div>
                                <h2>Customer Support</h2>
                                <p>Providing the customer with the flows to raise any ticket with the merchant in case of product issues or delivery issues. Tickets raised are directly integrated with the merchants CRM platform</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/cursor-icon.png" alt="" />
                                </div>
                                <h2>Conversion Multiplier</h2>
                                <p>Increase customer conversion by providing customers product recommendation based on customer needs. Reducing funnel drop offs by engaging with the dropped out customers and reinitiating their journey. Increase customer reach by facilitating complete BOT experience on Social Channels like Whatsapp, Instagram, FB Messenger</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/ai-icon.png" alt="" />
                                </div>
                                <h2>BOT Campaigns</h2>
                                <p>Run drip campaigns on Whatsapp. Build a comprehensive chat marketing strategy with various drip campaigns like dropoff campaigns, upsell and cross sell campaigns. Drive post purchase campaigns like referrals, reviews, feedback, product tip and loyalty</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/tree-icon.png" alt="" />
                                </div>
                                <h2>BOT Channel</h2>
                                <p>Integrate BOT as a channel on digital campaigns to drive customers directly on the both. Start the customer journey on the BOT based on the campaign they came from.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    );
}

export default Solutions;