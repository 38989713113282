import React from 'react';

function Footer() {
    return (
        <div>
            <div className="banner">
                <div className="wrapper">
                    <section>
                        <div className="banner-content">
                            <h1>Choose your plan now</h1>
                            <p>Choose from variety of our plans to pick the one that fits you most. Pay for results, not for time of usage!</p>
                            <a href="/pricing">Choose a plan</a>
                        </div>
                        <div className="banner-image">
                            <img src="images/banner1.png" alt="vbot-dashboard-preview" />
                        </div>
                    </section>
                </div>
            </div>

            <footer>
                <div className="wrapper">
                    <section>
                        <div className="logo">
                            <img src="images/logo.svg" alt="Vartalap AI" height="45px" />
                        </div>

                        <div className="footer-links">
                            <span> &copy; 2023 </span><span>|</span>
                            <a href="/security">Security</a><span>|</span>
                            <a href="/privacy">Privacy</a><span>|</span>
                            <a href="/terms">Terms</a>
                        </div>
                    </section>
                </div>
            </footer>

        </div>
    );
}

export default Footer;
