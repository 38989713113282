import React from 'react';

function NotFound() {

    return (
        <div>
            <div className="header-container">
                <div className="wrapper">
                    <header>
                        <div className="hero-content not-found">
                            <h1>Great things coming soon.</h1>
                            <span>We are still working to stand on our feet and start running soon.</span>
                            {/* <form  onSubmit={handleSubmit}>
                                <input type="email" name="email" placeholder='Email Adderess' />
                                <button>Notify Me</button>
                            </form> */}
                        </div>
                    </header>
                </div>
            </div>

        </div>
    );
}

export default NotFound;
