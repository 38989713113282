import React from 'react';

function Pricing() {
    return (
        <div>
                        <div className="solutions small-grid">
                <div className="wrapper">
                    <section>
                        <h1>Pricing options</h1>
                        <div className="content fv">
                            <div className="sub-content">
                                <h2>STARTER</h2>
                                <div className="price">$19 <span>/month</span></div>
                                <div className="sub-text">For small businesses</div>
                                <ul>
                                    <li>2 Agent logins</li>
                                    <li>Custom Inbox</li>
                                    <li>500 WhatsApp outbound messages</li>
                                </ul>
                                <a href="choose.html">Choose Plan</a>
                            </div>
                            <div className="sub-content">
                                <h2>GROWTH</h2>
                                <div className="price">$199 <span>/month</span></div>
                                <div className="sub-text">For rapidly growing brands</div>
                                <ul>
                                    <li>3 Agent logins</li>
                                    <li>All Meta inboxes, email, live chat</li>
                                    <li>Outbound messages</li>
                                    <li>1000 Bot sessions ($25/1000 additional sessions)</li>
                                    <li>Catalog Management</li>
                                    <li>Campaign, agent, bot analytics</li>
                                    <li>Product onboarding Services</li>
                                </ul>
                                <a href="choose.html">Choose Plan</a>
                            </div>
                            <div className="sub-content">
                                <h2>PRO</h2>
                                <div className="price">$499 <span>/month</span></div>
                                <div className="sub-text">For scaling businesses</div>
                                <ul>
                                    <li>5 Agent logins</li>
                                    <li>All Meta inboxes, email, live chat</li>
                                    <li>Outbound messages</li>
                                    <li>5000 Bot sessions ($25/1000 additional sessions)</li>
                                    <li>Catalog Management</li>
                                    <li>Campaign, agent, bot analytics</li>
                                    <li>Product onboarding Services</li>
                                    <li>Deep user analytics</li>
                                </ul>
                                <a href="choose.html">Choose Plan</a>
                            </div>
                            <div className="sub-content">
                                <h2>ENTERPRISE</h2>
                                <div className="price">Custom</div>
                                <div className="sub-text">For enterprise businesses</div>
                                <ul>
                                    <li>Custom Agent logins</li>
                                    <li>All Meta inboxes, email, live chat</li>
                                    <li>Outbound messages</li>
                                    <li>Custom Bot sessions</li>
                                    <li>Catalog Management</li>
                                    <li>Campaign, agent, bot analytics</li>
                                    <li>Product onboarding Services</li>
                                    <li>Deep user analytics</li>
                                </ul>
                                <a href="choose.html">Choose Plan</a>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Pricing;