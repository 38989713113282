import React from 'react';

function About() {
    return (
        <div>
            <div className="subpage-header ">
                <div className="wrapper">
                    <section>
                        <div className="cust-content">
                            <h1>
                                A few words about us
                            </h1>
                            <p>
                                We are an experienced team of technology entrepreneurs with a vision to help our customers crack the code of NLP and use it to its fullest potential for their ventures and initiatives. We believe that BOTs will become one of the main channels of personalizing customer engagement and experience.                            </p>
                        </div>
                        <div className="cust-image">
                            <img src="images/about-head.png" alt="Vartalap Solutions" />
                        </div>
                    </section>
                </div>
            </div>

            <div className="solutions">
                <div className="wrapper">
                    <section>
                        <h1>Our Team</h1>
                        <div className="content fv">
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/arun_menon.png" alt="" />
                                </div>
                                <h2>Arun Menon</h2>
                                <p>Co-Founder, CEO</p>
                                <p>Arun Menon is an IIT-B alumni with a passion for technology, love for solving problems and taking on challenges which led him to build multiple technology companies over the last two decades. Arun has deep knowledge of varied technologies as well as markets and has delivered many first to market products and business models. In his last start-up Arun sold mobile applications to over 5 million customers in India through a unique distribution network spanning 800 cities and 15000 points of presence.</p>
                            </div>
                            <div className="sub-content">
                                <div className="icon">
                                    <img src="images/kamal_saboo.png" alt="" />
                                </div>
                                <h2>Kamal Saboo</h2>
                                <p>Co-Founder, COO</p>
                                <p>Seasoned and highly committed professional with proven strategic thinking and leadership skills. IIT Bombay, 2003 alumni having a multi-faceted background spanning various roles, project planning, UX, dev-ops, development, testing automation and key client management. Having strong foundation and understanding of technology, with hands on experience on numerous technologies along with proven track record of delivering key turn around projects. Domain expertise includes Fintech Solutions, eComm Lifecycle and MicroSite development.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    );
}

export default About;